import { eventQBus } from "../../types/EventQBus";
import { AvContent } from "./AvContent";

let singleton: EntryRule | undefined;

export class EntryRule {
  /*               */
  private constructor(readonly rule: string) {}

  /*                  */

  static instance() {
    if (!singleton) {
      const rule = EntryRule.entryRule;
      if (rule) {
        singleton = new EntryRule(rule);
      }
    }
    return singleton;
  }

  /*               */

  static register(): void {
    const entryRule = EntryRule.instance();
    if (entryRule) {
      eventQBus.on("ftfind.history.merge", () => entryRule.updateHistoryState());
      eventQBus.on("ftfind.history.push", () => entryRule.updateHistoryState());
      window.addEventListener("pageshow", () => entryRule.trackSanEntrySelectionRule());
    }
  }

  /*                       */

  static get entryRule() {
    return EntryRule.historyRule || AvContent.pageRule();
  }

  private static get historyRule(): string | undefined {
    return window.history.state?.entryRule;
  }

  private static set historyRule(entryRule: string | undefined) {
    if (entryRule) {
      window.history.replaceState(
        {
          ...window.history.state,
          entryRule,
        },
        ""
      );
    }
  }

  updateHistoryState(): void {
    EntryRule.historyRule = this.rule;
  }

  trackSanEntrySelectionRule() {
    eventQBus.emit("tracking.bct.addToPageImpression", {
      "wk.san_EntrySelectionRule": this.rule,
    });
  }
}
